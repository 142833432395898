*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

input,
textarea {
    font-family: inherit;
    margin: 0
}

.slidinput-wrapper {
    position: relative;
    display: inline-block
}

.slidinput-wrapper input {
    box-sizing: border-box !important
}

.slidinput-placeholder {
    --sliding-y: -90%;
    --sliding-x: 0;
    --scaling: 0.7;
    --opacity: 1;
    position: absolute;
    transform-origin: 0% 0%;
    transition: all 250ms ease;
    transition-property: transform, color, opacity
}

.slidinput-wrapper.focused.focus-animation .slidinput-placeholder,
.slidinput-wrapper.filled .slidinput-placeholder {
    opacity: var(--opacity);
    transform: scale(var(--scaling)) translate(var(--sliding-x), var(--sliding-y))
}