@media screen and (max-width: 1200px) and (min-width: 240px) {
	.page_width {
		width: 100%;
		padding: 0px 15px;
	}
}

@media screen and (max-width: 1024px) and (min-width: 240px) {
	.page_width {
		width: 100%;
		padding: 0px 15px;
	}

	.gerenric_heading {
		font-size: 35px;
		margin-bottom: 30px;
	}

	.gerenric_padding {
		padding: 50px 0px;
	}

	.gerenric_button {
		height: 70px;
	}

	.gerenric_form_content .fc_left {
		width: 100%;
		padding-right: 0px;
	}

	.gerenric_form_content .fc_right {
		width: 100%;
	}

	.gerenric_form_content .fc_top_bg {
		width: 180px;
	}

	.gerenric_founder {
		margin-bottom: 50px;
	}

	.gerenric_founder .founder_block .founder_row {
		display: block;
		margin-left: 0px;
		margin-right: 0px;
	}

	.gerenric_founder .founder_block .founder_name {
		font-size: 26px;
		padding: 0px;
		margin-bottom: 10px;
	}

	.gerenric_team .slick-list {
		padding: 0px 60px 0px 0px !important;
	}

	.gerenric_team .slick-slider {
		margin-right: -15px;
	}

	.gerenric_team.column_full .team_col {
		padding-right: 40px;
	}

	.gerenric_team .team_detail .team_name {
		margin-bottom: 10px;
		font-size: 26px;
	}

	.gerenric_team .team_detail .team_designation {
		margin-bottom: 10px;
	}

	.gerenric_content_bg {
		width: 170px;
		overflow: hidden;
		position: absolute;
		right: -50px;
		top: -80px;
	}

	.gerenric_content {
		width: 100%;
		padding-right: 0px;
		padding-top: 30px;
	}

	.gerenric_content h2 {
		margin-bottom: 20px;
	}

	#header_section {
		padding: 34px 0px;
	}

	#header_section .page_width {
		padding: 0px 15px;
	}

	#header_section.headersticky {
		height: 70px;
		padding-top: 30px;
	}

	.menu_icon {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		border-radius: 0%;
		cursor: pointer;
		display: flex !important;
		position: absolute;
		right: 0px;
		top: -7px;
	}

	.menu_icon:hover .Mbarline {
		width: 25px;
	}

	.menu_icon.closeMenu .Mbarline {
		width: 25px;
	}

	.menu_icon.closeMenu .Mbarline:first-child {
		-webkit-transform: translateY(7px) rotate(45deg);
		transform: translateY(7px) rotate(45deg);
	}

	.menu_icon.closeMenu .Mbarline:nth-child(2) {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	.menu_icon.closeMenu .Mbarline:last-child {
		-webkit-transform: translateY(-7px) rotate(-45deg);
		transform: translateY(-7px) rotate(-45deg);
	}

	.Mbarline {
		width: 25px;
		height: 2px;
		background: #fff;
		-webkit-transition: 0.3s ease-in-out;
		transition: 0.3s ease-in-out;
	}

	.Mbarline:nth-child(2) {
		width: 25px;
		margin: 5px 0;
	}

	.Mbarline:last-child {
		width: 25px;
	}

	#logo {
		width: 130px;
	}

	#navigation_section {
		background-color: #00191F;
		padding: 0px;
	}

	#navigation_section ul {
		width: 100%;
		height: 100vh;
		background-color: #00191F;
		display: none;
		padding-bottom: 80px;
		overflow-y: auto;
		position: fixed;
		top: 68px !important;
		z-index: 99;
		left: 0px;
	}

	#navigation_section ul::after {
		width: 300px;
		height: 220px;
		content: '';
		position: absolute;
		right: 0px;
		bottom: 0px;
	}

	#navigation_section ul.showMenu {
		display: block;
		transition: all 0.3s;
	}

	#navigation_section ul li {
		display: block;
		font-size: 24px;
		padding: 25px 20px;
	}

	#navigation_section ul li a {
		position: relative;
	}

	#navigation_section ul li.menu_mobile_show {
		display: block;
	}

	#navigation_section ul li p {
		font-size: 24px;
		color: #fff;
		font-weight: 400;
		line-height: 150%;
		margin-bottom: 20px;
	}

	#navigation_section ul li .gerenric_button {
		min-width: 190px;
		height: 60px;
		background-color: #fff;
		color: #070A11;
	}

	#navigation_section ul li .gerenric_button:hover {
		background-color: #FF5F45;
		color: #fff;
	}

	#navigation_section ul li .nav_join_us_text {
		display: inline-block;
		border-bottom: 4px solid #FF5F45;
	}

	#navigation_section ul li.nav_creative_text {
		color: #70737C;
		font-size: 18px;
	}

	#navigation_section ul li .mobile_menu_bg {
		text-align: right;
		position: relative;
		bottom: -55px;
		right: 0px;
	}

	#header_section .header_language {
		right: 40px;
	}

	.banner_section .banner_detail {
		padding-right: 80px;
	}

	.banner_section .banner_detail .banner_title {
		font-size: 30px;
	}

	.banner_section .banner_detail .banner_creative_text {
		font-size: 30px;
	}

	#banner_section {
		padding-top: 8px;
	}

	.our_mission_section p {
		font-size: 24px;
	}

	.what_we_section .what_we_detail ul li {
		font-size: 20px;
	}

	.what_we_section .what_we_detail p {
		font-size: 20px;
		line-height: 160%;
	}

	.shape_section {
		background-position: left 350px, right 40px;
	}

	.shape_section.gerenric_padding {
		padding: 80px 0px 40px 0px;
	}

	.shape_section .shape_block_1 .shape_detail {
		padding: 45px;
	}

	.shape_section .shape_block_1 .shape_detail .shape_logo {
		margin-bottom: 30px;
	}

	.shape_section .shape_block_1 .shape_detail h2 {
		font-size: 30px;
	}

	.shape_section .shape_block_1 {
		margin-bottom: 40px;
	}

	.shape_section .shape_item .shape_item_inner {
		padding: 15px;
	}

	.shape_section .shape_item p {
		font-size: 20px;
		line-height: 160%;
	}

	.education_section .education_image {
		padding-right: 25px;
	}

	.education_section .education_detail p {
		font-size: 22px;
		line-height: 160%;
	}

	.sturctured_product_section .sturctured_product_inner {
		width: 85%;
	}

	.about_page .gerenric_content_bg {
		display: none;
	}

	.about_page .about_team_section {
		top: -40px;
		margin-bottom: -40px;
	}

	.about_page .about_team_section .about_team_inner.gerenric_padding {
		padding-top: 20px;
	}

	.about_page .about_team_section .about_team_bg_1 {
		width: 220px;
		left: -50px;
	}

	.about_page .about_team_section .about_team_bg_2 {
		height: 300px;
		bottom: 50px;
	}

	.about_page .about_team_section .about_team_bg_2 img {
		max-height: 100%;
	}

	.about_page .about_team_section .gerenric_heading {
		margin-bottom: 40px;
		font-weight: 600;
	}

	.about_page .about_different_section .about_col {
		width: 100%;
		padding: 0px;
		margin-bottom: 40px;
	}

	.about_page .about_different_section .about_item .about_icon {
		margin-bottom: 15px;
	}

	.about_page .about_different_section .about_item .about_title {
		height: auto;
	}

	.about_page .about_different_section .about_item ul li {
		margin-bottom: 20px;
	}

	.solution_page .solution_step {
		overflow: hidden;
	}

	.solution_page .solution_step .solution_step_image {
		width: 100%;
		margin-top: 0rem;
	}

	.solution_page .solution_step .solution_step_image img {
		width: 100%;
	}

	.step_block {
		grid-template-columns: 1fr !important;
	}

	.solution_page .solution_step .solution_step_detail {
		width: 100%;
		float: none;
		padding-right: 0px;
	}

	.solution_page .solution_step .solution_step_detail .step_title {
		margin-bottom: 15px;
	}

	.solution_page .solution_step .solution_step_detail p {
		margin-bottom: 15px;
	}

	.solution_page .solution_product .sp_bg {
		width: 170px;
		left: -50px;
		top: 20px;
	}

	.solution_page .solution_product .sp_col {
		width: 100%;
	}

	.solution_page .solution_product .sp_item_inner {
		min-height: inherit;
	}

	.solution_page .solution_product_price .sp_price_box {
		display: block;
		padding: 30px 20px;
	}

	.solution_page .solution_product_price .sp_price_icon {
		width: 100%;
		text-align: center;
		margin-bottom: 25px;
	}

	.solution_page .solution_product_price .sp_price_text {
		width: calc(100% - 0px);
		padding-left: 0px;
		text-align: center;
	}

	.shape_page .shape_heading .gerenric_heading {
		width: 100%;
		margin-bottom: 40px;
	}

	.commitment_page .commitment_block {
		display: block;
		height: auto;
	}

	.commitment_page .commitment_left {
		width: 100%;
		padding: 0px;
	}

	.commitment_page .commitment_left .commitment_title {
		font-size: 24px;
		padding-top: 20px;
		font-weight: 600;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.commitment_page .commitment_left .commitment_image {
		position: inherit;
	}

	.commitment_page .commitment_left .commitment_image img.cm_desktop {
		display: none;
	}

	.commitment_page .commitment_left .commitment_image img.cm_mobile {
		display: block;
	}

	.commitment_page .commitment_right {
		width: calc(100% - 0px);
		padding: 0px 20px;
	}

	.commitment_page .commitment_right ul {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.join_us_page .join_bg {
		display: none;
	}

	.join_us_page .gerenric_heading {
		width: 100%;
	}

	.join_us_page .join_apply .apply_link a {
		text-decoration: underline;
		word-break: break-all;
	}

	.join_us_page .join_apply .apply_link a::after {
		display: none;
	}

	.compliance_page .section_1 {
		margin-bottom: 70px;
	}

	.compliance_page .section_1 p {
		font-size: 24px;
	}

	.compliance_page .section_1 p a {
		text-decoration: underline;
		word-break: break-all;
	}

	.compliance_page .section_1 p a::after {
		display: none;
	}

	.compliance_page .customer_issuer {
		display: block;
	}

	.compliance_page .customer_issuer .ci_col {
		width: 100%;
		margin-bottom: 30px;
	}

	.compliance_page .customer_issuer .ci_col:nth-child(1) {
		padding-right: 0px;
	}

	.compliance_page .customer_issuer .ci_col:nth-child(2) {
		padding-left: 0px;
	}

	.compliance_page .customer_issuer .ci_title {
		font-size: 24px;
		margin-bottom: 15px;
	}

	.contact_page .main_contact_map {
		padding: 40px 0px;
	}

	#footer_section {
		background-size: auto, 250px;
	}

	#footer_section .footer_top {
		padding: 50px 0px 20px 0px;
	}

	#footer_section .footer_top .footer_col {
		width: 50% !important;
		padding-right: 30px;
		margin-bottom: 40px;
	}

	#footer_section .footer_top .footer_col:nth-child(2) {
		padding-right: 0px;
	}

	#footer_section .footer_top .footer_col:nth-child(3) {
		margin-bottom: 0px;
	}

	#footer_section .footer_top .footer_col:nth-child(4) {
		padding-right: 0px;
		margin-bottom: 0px;
	}

	#footer_section .footer_top ul li {
		margin-bottom: 20px;
		font-size: 22px;
	}

	#footer_section .footer_top p {
		font-size: 22px;
	}

	#footer_section .footer_bottom {
		padding-right: 0px;
		padding-bottom: 160px;
	}

	#footer_section .footer_bottom ul {
		flex-wrap: wrap;
	}

	#footer_section .footer_bottom ul li {
		width: 50%;
		margin-bottom: 25px;
		padding-left: 0px;
	}

	#footer_section .footer_bottom ul li:nth-child(5) {
		width: 100%;
	}

	.team_slider_img {
		grid-template-columns: 1fr 1fr 1fr;
	}

}

@media screen and (max-width: 800px) and (min-width: 240px) {
	.team_slider_img {
		grid-template-columns: 1fr 1fr;
	}

	#banner_section {
		padding-top: 20px;
	}
}

@media screen and (max-width: 650px) and (min-width: 240px) {
	.gerenric_heading {
		font-size: 32px;
	}

	.team_slider_img {
		grid-template-columns: 1fr;
	}

	.gerenric_padding {
		padding: 40px 0px;
	}

	.gerenric_button {
		min-width: 160px;
	}

	.gerenric_form_content .gerenric_heading {
		font-size: 32px;
	}

	.banner_section {
		padding: 30px 0px 50px 0px;
	}

	.banner_section .banner_detail {
		padding-right: 30px;
	}

	.banner_section .banner_detail .banner_adyo_logo {
		margin: 10px 0px;
	}

	.banner_section .banner_detail .banner_title {
		font-size: 22px;
	}

	.banner_section .banner_detail .banner_creative_text {
		font-size: 16px;
	}

	#banner_section {
		padding-top: 30px;
	}

	.our_mission_section .our_mission_inner {
		width: 100%;
		margin-top: 4rem;
	}

	.what_we_section .what_we_image {
		position: relative;
		left: -30px;
		top: 0px;
		width: 100%;
	}

	.what_we_section .what_we_detail {
		width: 100%;
		padding-left: 0px;
		float: none;
	}

	.what_we_section .what_we_detail .gerenric_button {
		display: flex;
	}

	.shape_section {
		background-size: 200px;
		background-position: -20% 200px, 130% 20px;
	}

	.shape_section.gerenric_padding {
		padding-top: 60px;
	}

	.shape_section .shape_block_1 {
		margin-bottom: 20px;
	}

	.shape_section .shape_block_1 .shape_detail {
		padding: 60px 20px;
	}

	.shape_section .shape_block_1 .shape_detail h2 {
		font-size: 22px;
	}

	.shape_section .shape_col {
		width: 100%;
		padding: 5px 0px;
	}

	.shape_section .shape_item p {
		height: auto;
	}

	.education_section .education_inner {
		display: block;
	}

	.education_section .education_image {
		width: 100%;
		padding-right: 0px;
		margin-bottom: 30px;
	}

	.education_section .education_image img {
		width: 100%;
	}

	.education_section .education_detail {
		width: 100%;
	}

	.sturctured_product_section .sturctured_product_inner {
		width: 100%;
	}

	.sturctured_product_section .gerenric_heading {
		font-size: 24px;
	}

	.sturctured_product_section .gerenric_button {
		display: flex;
	}

	/* .solution_page .solution_step .solution_step_image img {
		max-width: 300px;
	} */

	.shape_page .shape_heading .gerenric_heading {
		text-align: left;
	}

	.shape_page .gerenric_button {
		display: flex;
	}

	.join_us_page .gerenric_heading {
		text-align: left;
	}

	.join_us_page .join_apply {
		margin-bottom: 70px;
	}


	#footer_section {
		background-size: auto, 200px;
	}

	#footer_section .footer_top .footer_col {
		width: 100% !important;
		padding-right: 0px;
		margin-bottom: 0px;
	}

	#footer_section .footer_top .footer_col:nth-child(1) {
		margin-bottom: 40px;
	}

	#footer_section .footer_top ul li {
		margin-bottom: 40px;
	}

	#footer_section .footer_bottom {
		padding-top: 0px;
		padding-bottom: 120px;
	}

	#footer_section .footer_bottom ul {
		display: block;
	}

	#footer_section .footer_bottom ul li {
		width: 100%;
	}
}

@media screen and (max-width: 480px) and (min-width: 240px) {
	#banner_section {
		padding-top: 40px;
	}
}

@media screen and (max-width: 380px) and (min-width: 240px) {
	#banner_section {
		padding-top: 47px;
	}
}

@media screen and (max-width: 320px) and (min-width: 240px) {}

@media screen and (max-width: 270px) and (min-width: 240px) {}