/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
  border: none !important;
  border-radius: 0px !important;
}

.accordion__item + .accordion__item {
  border-top: none !important;
}

.accordion__button {
  display: flex !important;
  justify-content: center;
  background-color: transparent !important;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: center;
  border: none;
}

.accordion__button:hover {
  background-color: transparent !important;
}

.accordion__button:after {
  display: none !important;
  content: "";
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded="true"]::after,
.accordion__button[aria-selected="true"]::after {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 5px 0px;
  animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
