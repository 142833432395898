@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;300;400;500;600;700;800;900&display=swap');

body {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    color: #838383;
    font-family: 'Work Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
div,
ol,
span,
input,
textarea,
div,
class,
select,
section,
header,
footer,
img {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

#container {
    width: 100%;
}

.full_width {
    width: 100%;
}

.page_width {
    width: 1180px;
    text-align: left;
}

.left {
    float: left;
}

.right {
    float: right;
}

.left_side {
    float: left;
}

.right_side {
    float: right;
}

.col {
    float: left;
}

.clearfix {
    clear: both;
}

a img {
    border: 0px;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
}

.hide {
    display: none;
}

.wd_100 {
    width: 100%;
}

.wd_95 {
    width: 95%;
}

.wd_90 {
    width: 90%;
}

.wd_85 {
    width: 85%;
}

.wd_80 {
    width: 80%;
}

.wd_75 {
    width: 75%;
}

.wd_70 {
    width: 70%;
}

.wd_65 {
    width: 65%;
}

.wd_60 {
    width: 60%;
}

.wd_55 {
    width: 55%;
}

.wd_50 {
    width: 50%;
}

.wd_45 {
    width: 45%;
}

.wd_40 {
    width: 40%;
}

.wd_35 {
    width: 35%;
}

.wd_30 {
    width: 30%;
}

.wd_25 {
    width: 25%;
}

.wd_20 {
    width: 20%;
}

.wd_15 {
    width: 15%;
}

.wd_10 {
    width: 10%;
}

.txt_align_left {
    text-align: left;
}

.txt_align_center {
    text-align: center;
}

.txt_align_right {
    text-align: right;
}

.position_relative {
    position: relative;
}

p {
    font-size: 15px;
    color: #000;
    text-align: left;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 10px;
}

h1 {
    font-size: 28px;
    color: #000;
    text-align: left;
    font-weight: normal;
    margin-bottom: 15px;
}

h2 {
    font-size: 22px;
    color: #000;
    text-align: left;
    font-weight: normal;
    margin-bottom: 15px;
}

h3 {
    font-size: 20px;
    color: #000;
    text-align: left;
    font-weight: normal;
    margin-bottom: 15px;
}

a {
    color: #070A11;
}

a.link_underline {
    text-decoration: underline;
}

/*GERENRIC_SECTION_START*/
.mb_20 {
    margin-bottom: 20px !important;
}

.mt_30 {
    margin-top: 30px;
}

.gerenric_padding {
    padding: 80px 0px;
}

.gerenric_heading {
    width: 100%;
    line-height: 160%;
    font-size: 40px;
    font-weight: 400;
    color: #070A11;
    margin-bottom: 40px;
}

.gerenric_heading.hdg_white {
    color: #fff;
}

.gerenric_heading span {
    font-weight: 600;
    position: relative;
}

.gerenric_heading span::after {
    width: 100%;
    height: 4px;
    background-color: #FF5F45;
    content: '';
    position: absolute;
    left: 0px;
    bottom: -3px;
}

.gerenric_heading span.hdg_border_none::after {
    display: none;
}

.gerenric_heading span.bottom_border_orange,
.shape_detail span.bottom_border_orange {
    border-bottom: 4px solid #FF5F45;
}

.gerenric_heading span.bottom_border_yellow,
.shape_detail span.bottom_border_yellow {
    border-bottom: 4px solid #FFA540;
}

.gerenric_heading span.bottom_border_blue,
.shape_detail span.bottom_border_blue {
    border-bottom: 4px solid #006A99;
}

.gerenric_button {
    min-width: 280px;
    font-size: 22px;
    border: 0px;
    cursor: pointer;
    font-weight: 600;
    color: #fff;
    height: 80px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #FF5F45;
    padding: 10px 30px;
    border-radius: 8px;
}

.gerenric_button.bg_blue {
    background-color: #00191F;
}

.gerenric_form_content {
    width: 100%;
    min-height: 600px;
    position: relative;
    background-color: #00191F;
}

.gerenric_form_content .page_width {
    position: relative;
    z-index: 9;
}

.gerenric_form_content .gerenric_form_content_inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.gerenric_form_content .gerenric_form_content_inner.align_center {
    align-items: center;
}

.gerenric_form_content .gerenric_heading {
    font-size: 36px;
}

.gerenric_form_content .fc_bg {
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.gerenric_form_content .fc_bg img {
    display: block;
}

.gerenric_form_content .fc_top_bg {
    position: absolute;
    left: -90px;
    top: -120px;
}

.gerenric_form_content .fc_top_bg img {
    display: block;
}

.gerenric_form_content .fc_left {
    width: 58%;
    padding-right: 120px;
}

.gerenric_form_content .fc_right {
    width: 42%;
}

.gerenric_form_content .fc_detail {
    width: 100%;
}

.gerenric_form_content .fc_detail ul li {
    list-style-type: none;
    font-size: 20px;
    line-height: 160%;
    font-weight: 400;
    color: #fff;
    margin-bottom: 70px;
}

.gerenric_form_content .fc_detail ul li a {
    color: #fff;
}

.gerenric_form_content .fc_detail ul li .fc_label {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    color: #70737C;
    margin-bottom: 10px;
}

.gerenric_form_content .fc_detail ul li .fc_value {
    width: 100%;
}

.gerenric_form_content .form_box {
    width: 100%;
    padding: 30px 20px;
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid #E5E7EA;
}

.gerenric_form_content .form_box h2 {
    font-size: 20px;
    line-height: 160%;
    font-weight: 600;
    color: #000000;
    margin-bottom: 20px;
}

.gerenric_form_content .form_box p {
    font-size: 15px;
    line-height: 160%;
    color: #000000;
    font-weight: 400;
    margin-bottom: 20px;
}

.gerenric_form_content .form_box p span {
    font-weight: 600;
}

.gerenric_form_content .form_box p a.request_text {
    color: #006A99;
    text-decoration: underline !important;
    font-weight: 500;
}

.gerenric_form_content .form_box ul li {
    list-style-type: none;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 160%;
    color: #000000;
    font-weight: 400;
}

.gerenric_form_content .form_box ul li:last-child {
    margin-bottom: 0px;
}

.gerenric_form_content .form_box ul li .gerenric_input {
    width: 100%;
    box-shadow: 0px 2px 2px 0px #E5E7EA70;
    padding: 10px 15px;
    height: 56px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #E5E7EA;
}

.gerenric_form_content .form_box ul li .input_icon {
    position: absolute;
    right: 20px;
    cursor: pointer;
}

.gerenric_form_content .form_box ul li .input_icon img {
    display: block;
}

.gerenric_form_content .form_box ul li .gerenric_button {
    display: flex;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    height: 48px;
    padding: 10px 20px;
}

.gerenric_form_content .form_box ul li .form_term {
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    color: #70737C;
}

.gerenric_form_content .form_box ul li .form_term a {
    color: #70737C;
    text-decoration: underline;
}

.gerenric_form_content .form_box ul li .form_term a:hover {
    color: #FF5F45;
}

.gerenric_form_content .form_box ul li .forgot_password {
    width: 100%;
    margin: 20px 0px 40px;
    font-size: 16px;
    color: #FF5F45;
    font-weight: 700;
    text-align: center;
}

.gerenric_form_content .form_box ul li .forgot_password a {
    color: #FF5F45;
}

.gerenric_form_content .form_box ul li .forgot_password a:hover {
    text-decoration: underline;
}


.gerenric_arrow_down_icon {
    width: 100%;
    margin-top: 20px;
    text-align: center;
}

.gerenric_founder {
    width: 100%;
    margin-bottom: 70px;
}

.gerenric_founder .founder_image {
    width: 100%;
    margin-bottom: 30px;
}

.gerenric_founder .founder_block {
    width: 100%;
}

.gerenric_founder .founder_block h2 {
    font-size: 22px;
    color: #FFA540;
    font-weight: 600;
    margin-bottom: 15px;
}

.gerenric_founder .founder_block .founder_row {
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.gerenric_founder .founder_block .founder_name {
    padding: 10px;
    font-size: 32px;
    line-height: 160%;
    color: #fff;
    font-weight: 500;
}

.gerenric_founder p {
    font-size: 18px;
    line-height: 160%;
    color: #fff;
    font-weight: 400;
    margin-bottom: 0px;
}

.gerenric_team {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.gerenric_team.display_flex_auto {
    display: inherit;
}

.gerenric_team.column_full .team_col {
    width: 100%;
}

.gerenric_team .team_col {
    width: 25%;
    padding: 5px;
}

.gerenric_team .team_image {
    width: 100%;
    margin-bottom: 20px;
}

.gerenric_team .team_image img {
    display: block;
}

.gerenric_team .team_detail {
    width: 100%;
}

.gerenric_team .team_detail .team_name {
    width: 100%;
    font-size: 27px;
    line-height: 160%;
    color: #fff;
    font-weight: 500;
    margin-bottom: 20px;
}

.gerenric_team .team_detail .team_designation {
    width: 100%;
    height: 70px;
    overflow: hidden;
    font-size: 22px;
    line-height: 160%;
    color: #FFA540;
    font-weight: 600;
    margin-bottom: 20px;
}

.gerenric_team .team_detail p {
    width: 100%;
    font-size: 18px;
    line-height: 160%;
    color: #fff;
    font-weight: 400;
    margin-bottom: 15px;
}

.gerenric_team .team_detail p:last-child {
    margin-bottom: 0px;
}

.gerenric_team .team_detail ul li {
    list-style-type: none;
    font-size: 18px;
    line-height: 160%;
    color: #fff;
    font-weight: 400;
    margin-bottom: 10px;
}

.gerenric_content_bg {
    width: 30%;
    text-align: right;
    position: absolute;
    right: 0px;
    top: -30px;
}

.gerenric_content {
    width: 100%;
    padding-right: 360px;
    font-size: 18px;
}

.gerenric_content p {
    text-align: justify;
}

.gerenric_content p u {
    font-weight: 600;
}

.gerenric_content p a {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
}


.gerenric_content .content_block {
    width: 100%;
    margin-bottom: 70px;
}

.gerenric_content .content_block:last-child {
    margin-bottom: 0px;
}

.gerenric_content h2,
.gerenric_content h3 {
    font-size: 28px;
    color: #000;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 30px;
}

.gerenric_content h3 {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
}


.gerenric_content .date_text {
    font-size: 14px;
    color: #70737C;
    font-weight: 700;
    margin-bottom: 25px;
}

.gerenric_content p {
    font-size: 24px;
    color: #070A11;
    font-weight: 400;
    line-height: 160%;
    margin-bottom: 2px;
}

.gerenric_content p span {
    font-weight: 600;
}

.gerenric_content ul {
    margin-left: 30px;
    list-style-type: none;
}

.gerenric_content ul li {
    font-size: 24px;
    color: #070A11;
    font-weight: 400;
    line-height: 160%;
    margin-bottom: 14px;
}

.gerenric_content ol {
    margin-left: 30px;
}

.gerenric_content ol li {
    font-size: 24px;
    color: #070A11;
    font-weight: 400;
    line-height: 160%;
    margin-bottom: 14px;
}

.gerenric_content ol li a {
    color: #070A11;
    text-decoration: underline;
}

.slick-prev,
.slick-next {
    display: none !important;
}

/*GERENRIC_SECTION_END*/

/*HEADER_SECTION_START*/
.scrollarrowtop {
    width: 50px;
    height: 50px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    display: none;
    position: fixed;
    z-index: 9999;
    bottom: 10%;
    right: 0px;
    background-color: #ED6A50;
    cursor: pointer;
}

.scrollarrowtop .fa-arrow-up {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu_icon {
    display: none;
}

#header_section {
    width: 100%;
    background-color: #00252D;
    border-bottom: 2px solid #00191F;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    padding: 27px 0px 27px 0px;
    position: relative;
    z-index: 999;
}

#header_section.login_pg_fixed_nav {
    position: fixed;
}

#header_section .page_width {
    width: 100%;
    padding: 0px 50px;
    margin: auto;
}

#header_section.headersticky {
    padding: 12px 0px 12px 0px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.10);
    z-index: 99999;
    position: fixed;
    top: 0;
}

#header_section .headerinner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

#logo {
    width: 130px;
    margin-top: 3px;
    cursor: pointer;
    position: absolute;
    left: 0px;
}

#logo img {
    display: block;
}

#navigation_section {
    width: 100%;
    text-align: left;
    padding-left: 140px;
    padding-right: 140px;
}

#navigation_section ul li {
    list-style-type: none;
    position: relative;
    padding: 10px 15px;
    display: inline-flex;
    font-weight: 500;
    font-size: 15px;
    color: #fff;
}

#navigation_section ul li span {
    display: inline-block;
    position: relative;
}

#navigation_section ul li span::before {
    width: 8px;
    height: 8px;
    background-color: #FFA540;
    content: '';
    position: absolute;
    left: -4px;
    bottom: 0px;
}

#navigation_section ul li span::after {
    width: 8px;
    height: 8px;
    background-color: #FF5F45;
    border-radius: 100px;
    content: '';
    position: absolute;
    right: -4px;
    top: 0px;
}

#navigation_section ul li span .shape_menu_text {
    position: relative;
    z-index: 99;
}

#navigation_section ul li:last-child {
    padding-right: 0px;
}

#navigation_section ul li a {
    color: #fff;
    position: relative;
}

#navigation_section ul li a:hover {
    font-weight: 700;
}

#navigation_section ul li a::after {
    width: 100%;
    height: 2px;
    display: none;
    background-color: #FFA540;
    content: '';
    position: absolute;
    left: 0px;
    bottom: -3px;
}

#navigation_section ul li a:hover::after {
    display: block;
}

#navigation_section ul li.menu_mobile_show {
    display: none;
}

#header_section .header_language {
    position: absolute;
    right: 0px;
    ;
}

#header_section .header_language ul {
    display: flex;
}

#header_section .header_language ul li {
    list-style-type: none;
    margin-left: 7px;
}

#header_section .header_language ul li:first-child {
    margin-left: 0px;
}

#header_section .header_language .language_box {
    width: 60px;
    height: 36px;
    font-size: 15px;
    color: #070A11;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 8px;
    background-color: #fff;
    border-radius: 8px;
}

#header_section .header_language .language_box img {
    margin-right: 5px;
}

#header_section .header_language .language_box.en_box {
    background-color: #00191F;
    color: #fff;
}

#header_section .header_language .language_box:hover {
    text-decoration: underline;
}

/*HEADER_SECTION_END*/

/*BANNER_SECTION_START*/
.banner_section {
    width: 100%;
    padding: 40px 0px 80px 0px;
}

.banner_section .banner_inner {
    width: 100%;
    display: flex;
    align-items: center;
}

.banner_section .banner_detail {
    width: 60%;
}

.banner_section .banner_detail .banner_title {
    font-size: 44px;
    font-weight: 400;
    color: #070A11;
}

.banner_arrow_btn {
    margin-top: -11rem;
    position: relative;
    z-index: 1;
}

.banner_section .banner_detail .banner_adyo_logo {
    width: 100%;
    margin: 20px 0px;
}

.banner_section .banner_detail .banner_creative_text {
    font-size: 44px;
    font-weight: 400;
    color: #ED6950;
}

.banner_section .banner_image {
    width: 40%;
}

#banner_section {
    width: 100%;
    position: relative;
}

.hm_header {
    display: none !important;
    position: fixed !important;
    top: 0;
}

.lottie1 {
    display: block !important;
    animation: headerAnimation 0.4s linear alternate;
}

.banner_down_arrow {
    position: relative;
    top: -150px;
}

/*BANNER_SECTION_END*/

/*CONTENT_SECTION_START*/
#content_section {
    width: 100%;
}

.our_mission_section {
    width: 100%;
    background-color: #F8F9FC;
}

.our_mission_section .our_mission_inner {
    width: 92%;
    margin: auto;
}

.our_mission_section p {
    font-size: 28px;
    color: #070A11;
    font-weight: 400;
    line-height: 160%;
    margin-bottom: 30px;
}

.our_mission_section p:last-child {
    margin-bottom: 0px;
}

.our_mission_section p span {
    font-weight: 600;
}

.our_mission_section p span.bottom_border_orange {
    border-bottom: 4px solid #FF5F45;
}

.our_mission_section p span.bottom_border_yellow {
    border-bottom: 4px solid #FFA540;
}

.our_mission_section p span.bottom_border_blue {
    border-bottom: 4px solid #006A99;
}

.what_we_section {
    width: 100%;
    background-color: #fff;
    padding-bottom: 50px !important;
}

.what_we_section .what_we_inner {
    width: 100%;
    position: relative;
}

.what_we_section .page_width::after {
    clear: both;
    display: block;
    content: '';
}

.what_we_section .what_we_image {
    width: 37%;
    position: absolute;
    top: -50px;
    left: 0px;
    margin: auto;
}

.what_we_section .what_we_detail {
    width: 63%;
    padding-left: 20px;
    float: right;
}

.what_we_section .what_we_detail p {
    font-size: 28px;
    color: #070A11;
    font-weight: 400;
    line-height: 160%;
    margin-bottom: 30px;
}

.what_we_section .what_we_detail ul {
    margin-bottom: 40px;
}

.what_we_section .what_we_detail ul li {
    list-style-type: none;
    position: relative;
    padding-left: 40px;
    font-size: 22px;
    color: #00252D;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 20px;
}

.what_we_section .what_we_detail ul li::after {
    width: 21px;
    height: 22px;
    background: url('../images/what_we_check_icon.svg') 0px 0px no-repeat;
    content: '';
    position: absolute;
    left: 0px;
    top: 7px;
}

.shape_section {
    width: 100%;
    background-color: #00191F;
    min-height: 800px;
    background-image: url('../images/shape_bg1.svg'), url('../images/shape_bg2.webp');
    background-position: left 350px, right 100px;
    background-repeat: no-repeat, no-repeat;
}

.shape_section.gerenric_padding {
    padding: 50px 0px 50px;
}

.shape_section .shape_block_1 {
    width: 100%;
    padding: 3px;
    border-radius: 20px;
    background: rgb(255, 95, 69);
    background: linear-gradient(121deg, rgba(255, 95, 69, 1) 0%, rgba(0, 37, 45, 1) 100%);
    margin-bottom: 80px;
}

.shape_section .shape_block_1 .shape_block_inner {
    width: 100%;
    background-color: #00252D;
    border-radius: 20px;
}

.shape_section .shape_block_1 .shape_detail {
    width: 100%;
    padding: 50px 85px;
}

.shape_section .shape_block_1 .shape_detail .shape_logo {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
}

.shape_section .shape_block_1 .shape_detail .shape_logo img {
    width: 200px;

}

.shape_section .shape_block_1 .shape_detail h2 {
    font-size: 40px;
    text-align: center;
    color: #fff;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 0px;
}

.shape_section .shape_block_1 .shape_image {
    width: 100%;
}

.shape_section .shape_block_1 .shape_image img {
    width: 100%;
    display: block;
}

.shape_section .shape_block_2 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.shape_section .shape_col {
    width: 50%;
    padding: 5px;
}

.shape_section .shape_item {
    width: 100%;
    padding: 3px;
    background: rgb(255, 95, 69);
    background: linear-gradient(121deg, rgba(255, 95, 69, 1) 0%, rgba(0, 37, 45, 1) 100%);
    border-radius: 20px;
}

.shape_section .shape_item .shape_item_inner {
    width: 100%;
    padding: 25px;
    background-color: #0B1A1D;
    border-radius: 20px;
}

.shape_section .shape_item .shape_image {
    width: 100%;
    margin-bottom: 30px;
}

.shape_section .shape_item .shape_image img {
    width: 100%;
    display: block;
}

.shape_section .shape_item p {
    font-size: 28px;
    height: 330px;
    overflow: hidden;
    color: #fff;
    font-weight: 400;
    line-height: 160%;
    margin-bottom: 0px;
}

.education_section {
    width: 100%;
    background-color: #fff;
}

.education_section .education_inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.education_section .education_image {
    width: 50%;
    padding-right: 70px;
}

.education_section .education_detail {
    width: 50%;
}

.education_section .education_detail p {
    font-size: 28px;
    color: #070A11;
    font-weight: 400;
    line-height: 160%;
    margin-bottom: 30px;
}

.sturctured_product_section {
    width: 100%;
    background-color: #FF5F45;
}

.sturctured_product_section.gerenric_padding {
    padding: 50px 0px;
}

.sturctured_product_section .sturctured_product_inner {
    width: 70%;
    margin: auto;
}

.sturctured_product_section .gerenric_heading {
    text-align: center;
}

/*CONTENT_SECTION_END*/

/*INNER_SECTION_START*/
.blur_content_bg {
    width: 100%;
    margin-top: -3rem;
}

.blur_content_bg img {
    width: 100%;
    display: block;
}

.about_page {
    width: 100%;
    overflow: hidden;
}

.about_page .gerenric_content_bg {
    width: 20%;
    right: -95px;
    top: 0px;
}

.about_page .about_different_section {
    width: 100%;
    background-color: #fff;
}

.about_page .about_different_section.gerenric_padding {
    padding-bottom: 0px;
}

.about_page .about_different_section .about_different_inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.about_page .about_different_section .about_col {
    width: 33.333%;
    padding: 10px;
}

.about_page .about_different_section .about_item {
    width: 100%;
}

.about_page .about_different_section .about_item .about_icon {
    width: 100%;
    height: 60px;
    margin-bottom: 25px;
}

.about_page .about_different_section .about_item .about_title {
    width: 100%;
    /* height: 75px;*/
    margin-bottom: 35px;
    /* overflow: hidden;*/
    font-weight: 600;
    font-size: 22px;
    color: #FF5F45;
    line-height: 160%;
}

.about_page .about_different_section .about_item ul li {
    list-style-type: none;
    line-height: 170%;
    padding-left: 40px;
    position: relative;
    font-size: 18px;
    color: #070A11;
    margin-bottom: 30px;
}

.about_page .about_different_section .about_item ul li span {
    font-weight: 600;
}

.about_page .about_different_section .about_item ul li::after {
    width: 21px;
    height: 22px;
    content: '';
    position: absolute;
    left: 0px;
    top: 7px;
    background: url('../images/what_we_check_icon.svg') 0px 0px no-repeat;
}

.about_page .about_team_section {
    width: 100%;
    position: relative;
    top: -80px;
    margin-bottom: -80px;
}

.about_page .about_team_section .gerenric_heading {
    margin-bottom: 90px;
}

.about_page .about_team_section .about_team_inner {
    width: 100%;
    min-height: 900px;
    position: relative;
    background-color: #00252D;
}

.about_page .about_team_section .about_team_inner.gerenric_padding {
    padding-top: 0px;
}

.about_page .about_team_section .about_team_bg_1 {
    position: absolute;
    left: 0px;
    top: 80px;
}

.about_page .about_team_section .about_team_bg_2 {
    position: absolute;
    right: 0px;
    bottom: 80px;
}

.about_page .about_team_section .page_width {
    position: relative;
    z-index: 99;
}

.about_page .about_team_section .gerenric_heading {
    text-align: center;
}

.solution_page {
    width: 100%;
}

.solution_page .solution_step {
    padding-bottom: 0px !important;
    width: 100%;
}

.solution_page .solution_step .solution_step_inner {
    width: 100%;
}

.solution_page .solution_step .solution_step_inner::after {
    clear: both;
    display: block;
    content: '';
}

.solution_page .solution_step .solution_step_image {
    width: 100%;
    margin-top: -5rem;
    /* float: right; */
}

.solution_page .solution_step .solution_step_detail {
    width: 100%;
    /* float: left; */
    padding-right: 30px;
}

.solution_page .solution_step .solution_step_detail .step_block {
    width: 100%;
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.solution_page .solution_step .solution_step_detail .step_title {
    font-size: 28px;
    font-weight: 600;
    line-height: 160%;
    color: #000000;
    margin-bottom: 20px;
}

.solution_page .solution_step .solution_step_detail p {
    font-size: 22px;
    font-weight: 400;
    line-height: 160%;
    color: #070A11;
    margin-bottom: 20px;
}

.solution_page .solution_step .solution_step_detail li {
    font-size: 22px;
    /*font-weight: 600;*/
    line-height: 25px;
    color: #070A11;
    margin-bottom: 20px;
    list-style: none;
    position: relative;
    padding-left: 40px;
}

.solution_page .solution_step .solution_step_detail li:after {
    background: url('../images/what_we_check_icon.svg') 0 0 no-repeat;
    content: "";
    height: 22px;
    left: 0;
    position: absolute;
    top: 7px;
    width: 21px;
}

.solution_page .solution_step .solution_step_detail p:last-child {
    margin-bottom: 0px;
}

.solution_page .solution_product {
    width: 100%;
    margin-top: -6rem;
}

.solution_page .solution_product .page_width {
    position: relative;
    z-index: 9;
}

.solution_page .solution_product .sp_bg {
    position: absolute;
    left: 0px;
    top: 60px;
}

.solution_page .solution_product .solution_product_inner {
    width: 100%;
    position: relative;
    background-color: #00252D;
}

.solution_page .solution_product .gerenric_heading {
    text-align: center;
}

.solution_page .solution_product .solution_product_row {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 9;
}

.solution_page .solution_product .sp_col {
    width: 33.333%;
    padding: 5px;
}

.solution_page .solution_product .sp_item {
    width: 100%;
    padding: 3px;
    background: rgb(255, 95, 69);
    background: linear-gradient(121deg, rgba(255, 95, 69, 1) 0%, rgba(0, 37, 45, 1) 100%);
    border-radius: 20px;
}

.solution_page .solution_product .sp_item.sp_blue_bg {
    background: #006A99;
    background: linear-gradient(121deg, #006A99 0%, rgba(0, 37, 45, 1) 100%);
}

.solution_page .solution_product .sp_item.sp_yellow_bg {
    background: #FFA540;
    background: linear-gradient(121deg, #FFA540 0%, rgba(0, 37, 45, 1) 100%);
}

.solution_page .solution_product .sp_item_inner {
    width: 100%;
    min-height: 570px;
    padding: 25px;
    background-color: #0B1A1D;
    border-radius: 20px;
}

.solution_page .solution_product .sp_item {
    background: rgb(255, 95, 69);
    background: linear-gradient(121deg, rgba(255, 95, 69, 1) 0%, rgba(0, 37, 45, 1) 100%);
}

.solution_page .solution_product .sp_item .sp_icon {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
}

.solution_page .solution_product .sp_item .sp_icon img {
    display: block;
    margin: auto;
}

.solution_page .solution_product .sp_item .sp_title {
    font-size: 22px;
    color: #FFA540;
    font-weight: 600;
    line-height: 160%;
    margin-bottom: 40px;
    /* height: 75px;
    overflow: hidden;*/
    text-align: center;
}

.solution_page .solution_product .sp_item ul li {
    list-style-type: none;
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    line-height: 160%;
    margin-bottom: 15px;
    position: relative;
    padding-left: 40px;
}

.solution_page .solution_product .sp_item ul li::after {
    width: 21px;
    height: 22px;
    content: '';
    position: absolute;
    left: 0px;
    top: 7px;
    background: url('../images/check_mark_yellow.svg') 0px 0px no-repeat;
}

.solution_page .solution_product_price {
    width: 100%;
}

.solution_page .solution_product_price .sp_price_row {
    width: 100%;
    margin-bottom: 15px;
}

.solution_page .solution_product_price .sp_price_box {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 125px;
    padding: 10px 30px;
    background-color: #FFA540;
    border-radius: 10px;
}

.solution_page .solution_product_price .sp_price_icon {
    width: 90px;
}

.solution_page .solution_product_price .sp_price_text {
    width: calc(100% - 90px);
    line-height: 160%;
    padding-left: 10px;
    font-size: 22px;
    font-weight: 400;
    color: #fff;
}

.solution_page .solution_product_price .sp_price_text span {
    font-weight: 600;
}

.shape_page {
    width: 100%;
    background-color: #00191F;
}

.shape_page .shape_heading {
    width: 100%;
}

.shape_page .shape_heading .gerenric_heading {
    width: 85%;
    text-align: justify;
    margin: auto;
    margin-bottom: 0px;
}

.shape_page .gerenric_button.bg_blue {
    background-color: #00242F;
}

.shape_page .shape_main_image {
    width: 100%;
    margin-top: 70px;
}

.shape_page .shape_section {
    background-image: none;
    min-height: inherit;
    margin-top: 50px;
}

.commitment_page {
    width: 100%;
    background-color: #F8F9FC;
}

.commitment_page .gerenric_heading {
    text-align: center;
}

.commitment_page .commitment_block {
    width: 100%;
    margin-bottom: 30px;
    overflow: hidden;
    min-height: 500px;
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    border: 1px solid #E5E7EA;
    border-radius: 15px;
}

.commitment_page .commitment_left {
    width: 450px;
    position: relative;
    padding-left: 70px;
    padding-top: 80px;
}

.commitment_page .commitment_left .commitment_title {
    font-size: 40px;
    line-height: 160%;
    margin-bottom: 40px;
    font-weight: 500;
    color: #070A11;
    text-align: left;
}

.commitment_page .commitment_left .commitment_image {
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
}

.commitment_page .commitment_left .commitment_image img {
    display: block;
}

.commitment_page .commitment_left .commitment_image img.cm_desktop {
    display: block;
}

.commitment_page .commitment_left .commitment_image img.cm_mobile {
    display: none;
}

.commitment_page .commitment_right {
    width: calc(100% - 450px);
    padding: 90px 70px;
}

.commitment_page .commitment_right p {
    font-size: 20px;
    line-height: 160%;
    margin-bottom: 25px;
    font-weight: 400;
    color: #070A11;
    text-align: left;
}

.commitment_page .commitment_right p span {
    font-weight: 600;
}

.commitment_page .commitment_right ul {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
}

.commitment_page .commitment_right ul li {
    list-style-type: none;
    padding: 0px 20px;
}

.join_us_page {
    width: 100%;
    background-color: #00191F;
}

.join_us_page .join_bg {
    position: absolute;
    left: 0px;
    top: 50px;
}

.join_us_page .page_width {
    z-index: 9;
    position: relative;
}

.join_us_page .gerenric_heading {
    width: 90%;
    text-align: center;
    margin: auto;
    margin-bottom: 50px;
}

.join_us_page .join_apply {
    width: 100%;
    margin-bottom: 160px;
}

.join_us_page .join_apply .apply_text {
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 15px;
}

.join_us_page .join_apply .apply_link {
    width: 100%;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
}

.join_us_page .join_apply .apply_link a {
    color: #FF5F45;
    position: relative;
}

.join_us_page .join_apply .apply_link a::after {
    width: 100%;
    height: 4px;
    content: '';
    background-color: #FF5F45;
    position: absolute;
    left: 0px;
    bottom: -4px;
}

.compliance_page {
    width: 100%;
    overflow: hidden;
}

.compliance_page .section_1 {
    width: 100%;
    margin-bottom: 60px;
}

.compliance_page .section_1 p {
    font-size: 28px;
    font-weight: 600;
    color: #000000;
    line-height: 160%;
    margin-bottom: 20px;
}

.compliance_page .section_1 p a {
    color: #FF5F45;
    position: relative;
}

.compliance_page .section_1 p a::after {
    width: 100%;
    height: 4px;
    content: '';
    background-color: #FF5F45;
    position: absolute;
    left: 0px;
    bottom: -4px;
}

.compliance_page .section_2 {
    width: 100%;
}

.compliance_page .customer_issuer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

.compliance_page .customer_issuer .ci_col {
    width: 50%;
}

.compliance_page .customer_issuer .ci_col:nth-child(1) {
    padding-right: 90px;
}

.compliance_page .customer_issuer .ci_col:nth-child(2) {
    padding-left: 90px;
}

.compliance_page .customer_issuer .ci_title {
    font-size: 28px;
    color: #000000;
    font-weight: 600;
    text-align: left;
    margin-bottom: 25px;
}

.compliance_page .customer_issuer .gerenric_button {
    display: flex;
}

.cookie_page,
.privacy_policy_page,
.legal_page {
    width: 100%;
    overflow: hidden;
}

.contact_page {
    width: 100%;
}

.contact_page .main_contact_map {
    width: 100%;
    padding: 60px 0px;
}

.contact_page .main_contact_map .map_div {
    width: 100%;
}

.contact_page .main_contact_map .map_div iframe {
    width: 100%;
    height: 330px;
    border-radius: 20px;
}

/*INNER_SECTION_END*/

/*FOOTER_SECTION_START*/
#footer_section {
    width: 100%;
    background-image: url('../images/footer_top_border.webp'), url('../images/footer_bg.svg');
    background-position: center 0px, right bottom;
    background-repeat: no-repeat, no-repeat;
    background-color: #00191F;
}

#footer_section .footer_top {
    width: 100%;
    padding: 50px 0px;
}

#footer_section .footer_top .footer_top_inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

#footer_section .footer_top .footer_logo {
    width: 100%;
    margin-bottom: 20px;
}

#footer_section .footer_top .footer_col {
    width: 25%;
    padding-right: 40px;
}

#footer_section .footer_top .footer_col:nth-child(1) {
    width: 30%;
}

#footer_section .footer_top .footer_col:nth-child(2) {
    width: 20%;
}

#footer_section .footer_top .footer_col:nth-child(3) {
    width: 25%;
}

#footer_section .footer_top .footer_col:nth-child(4) {
    width: 25%;
}


.active_menu {
    font-weight: 700;
    border-bottom: 2px solid #FFA540;
}

.active_menu::after {
    width: 100%;
    height: 2px;
    background-color: #FFA540;
    content: '';
    position: absolute;
    left: 0px;
    bottom: -3px;
}

#footer_section .footer_top ul li {
    list-style-type: none;
    font-size: 24px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 40px;
}

#footer_section .footer_top ul li span {
    border-bottom: 4px solid #FF5F45;
}

#footer_section .footer_top ul li span a {
    text-decoration: inherit !important;
}

#footer_section .footer_top ul li a {
    color: #fff;
}

#footer_section .footer_top ul li a:hover {
    color: #FF5F45;
    text-decoration: underline;
}

#footer_section .footer_top p {
    font-size: 24px;
    color: #fff;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 20px;
}

#footer_section .footer_top .gerenric_button {
    min-width: 190px;
    height: 60px;
    background-color: #fff;
    color: #070A11;
}

#footer_section .footer_top .gerenric_button:hover {
    background-color: #FF5F45;
    color: #fff;
}

#footer_section .footer_bottom {
    width: 100%;
    padding: 25px 300px 25px 0px;
}

#footer_section .footer_bottom ul {
    display: flex;
}

#footer_section .footer_bottom ul li {
    list-style-type: none;
    font-size: 18px;
    color: #70737C;
    font-weight: 400;
    padding-left: 30px;
}

#footer_section .footer_bottom ul li:first-child {
    padding-left: 0px;
}

#footer_section .footer_bottom ul li span {
    font-weight: 600;
}

#footer_section .footer_bottom ul li a {
    color: #70737C;
}

#footer_section .footer_bottom ul li a:hover {
    text-decoration: underline;
}

/*FOOTER_SECTION_END*/

/* extra css */

.team_slider_img {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
}

.error_msg {
    color: #FF5F45;
    font-size: 10px;
    margin: 15px 0px;
}

.error_msg_one {
    color: #FF5F45;
    font-size: 14px;
    margin: 15px;
}

.header_language a:hover {
    cursor: pointer;
}

.header_language a .language_box:hover {
    cursor: pointer;
    text-decoration: none;
}

.hideEn {
    width: 50px;
    height: 50px;
    color: #fff;
    font-size: 20px;
    z-index: 9999;
    background-color: #ed6a50;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0px;
    bottom: 10%;
}

.scrollarrowtop {
    width: 50px;
    height: 50px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    display: none;
    position: fixed;
    z-index: 9999;
    bottom: 10%;
    right: 0px;
    background-color: #ed6a50;
    cursor: pointer;
}

.shape_buttons {
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.shape_buttons .shape_inner_btn {
    margin: 0px 20px;
}

.sub-menu ul li {
    color: #FFA540 !important;
    cursor: pointer;
    font-size: 0.8rem !important;
}

.new_innovation_img {
    text-align: center;
    margin-top: 1rem;
}