/* Legal Page */
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;300;400;500;600;700;800;900&display=swap");
.legal_content {
  width: 80%;
  font-family: "Work Sans", sans-serif;
  @media screen and (max-width: 1000px) {
    width: 100% !important;
    padding: 0px 1.7rem;
  }
  .all_content {
    width: 100%;
    text-align: left;
    // margin-left: -13rem;
    @media screen and (max-width: 1000px) {
      margin-left: 0px !important;
      width: 100% !important;
    }
    h2 {
      font-size: 36px;
      font-weight: 600;
      font-family: "Work Sans", sans-serif;
      line-height: 48px;
      letter-spacing: 0.5%;
      color: #00252d;
      text-align: justify;
    }
    small {
      font-size: 15px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 9.5%;
      text-align: justify;
    }
    p {
      font-size: 15px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 9.5%;
      color: #00252d;
      padding: 0.3 rem 0;
      text-align: justify;
      a {
        font-size: 15px;
        font-weight: 500;
      }
    }
    ul {
      margin-bottom: 4rem;
    }
    li {
      list-style: none;
      font-size: 15px;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: 9.5%;
      color: #00252d;
      text-align: justify;
      a {
        font-size: 15px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 9.5%;
        color: #00252d !important;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    a {
      font-size: 15px;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: 9.5%;
      color: #00252d !important;
      &:hover {
        text-decoration: underline;
      }
    }
    h3 {
      font-size: 24px;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: 9.5%;
      color: #00252d;
      padding: 1rem 0;
      text-align: justify;
    }
  }
}

.for_sticky {
  position: relative;
  width: 100%;
  padding: 10px 0px;
}
.banner_arrow {
  @media screen and (max-width: 760px) {
    display: none !important;
  }
}

.small-text {
  p {
    font-size: 10px;
    text-align: justify;
    color: #00252d;
  }
}

.join-text {
  padding: 0px 5rem;
  @media screen and (max-width: 780px) {
    padding: 0px 0.5rem;
  }
}
